import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import getConfig from 'next/config';

import GET_CONFIGURATION from '../config/graphql/getConfiguration';
import GET_DICTIONARY from '../config/graphql/getDictionary';
import { LoadingContainer } from '../components/Layouts/AppWrapper/index.style';
import LoadingSpinner from '../components/ui/Loading/LoadingSpinner';
import IbePropTypes from '../IbePropTypes';
import getFetchPolicyWithExpiry from '../lib/getFetchPolicyWithExpiry';
import { setStorageItemList } from '../lib/storage';
import { CONFIG_LOAD_COMPLETE_TIME_IN_SESSION } from '../lib/constants';
import { FETCH_POLICY_KEYS, fetchPolicyOptions, setUpRefetchOnDateTime } from '../constants/FetchPolicy';

import { buildError } from '../lib/sentry';

const withAppData = (WrappedComponent) => {
  class WithAppData extends React.PureComponent {
    static propTypes = {
      configuration: PropTypes.shape(IbePropTypes.configuration),
      configurationError: PropTypes.shape({}),
      configurationRefetch: PropTypes.func,
      dictionary: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.dictionaryItem)),
      dictionaryError: PropTypes.shape({}),
    }

    static defaultProps = {
      configuration: undefined,
      configurationError: undefined,
      configurationRefetch: () => { },
      dictionary: undefined,
      dictionaryError: undefined,
    }

    timeoutId = undefined;

    componentDidMount() {
      const { publicRuntimeConfig } = getConfig();
      setUpRefetchOnDateTime(this.timeoutId, this.onRefetch, publicRuntimeConfig);
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutId);
    }

    onRefetch() {
      this.props.configurationRefetch();
      setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION.SHARED_CONFIG, 'time', new Date().getTime());
    }

    render() {
      const {
        configuration,
        configurationError,
        dictionary,
        dictionaryError,
        ...componentProps
      } = this.props;
      const awaitingData = !configuration || !dictionary;

      if (configurationError || dictionaryError) {
        const error = buildError('withAppData.render() queryError', {
          dictionaryError,
          configurationError,
        });
        throw error;
      }

      if (awaitingData) {
        return (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        );
      }

      return (
        <WrappedComponent
          appData={{
            dictionary, configuration,
          }}
          {...componentProps}
        />
      );
    }
  }

  return compose(
    graphql(GET_CONFIGURATION, {
      options: () => {
        const fetchPolicy = getFetchPolicyWithExpiry(
          FETCH_POLICY_KEYS.SHARED_CONFIG,
          fetchPolicyOptions,
        );
        return {
          fetchPolicy,
          onCompleted: () => {
            setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION.SHARED_CONFIG, 'time', new Date().getTime());
          },
        };
      },
      props: ({ data }) => ({
        configuration: data.configuration,
        configurationError: data.error,
        configurationRefetch: data.refetch,
      }),
    }),
    graphql(GET_DICTIONARY, {
      props: ({ data }) => ({
        dictionary: data.dictionary,
        dictionaryError: data.error,
      }),
    }),
  )(WithAppData);
};

export default withAppData;
